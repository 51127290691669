import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[appFocusFirstInvalidInput]',
    standalone: false
})
export class FocusFirstInvalidInputDirective {

  constructor(private el: ElementRef) { }

  @HostListener('submit')
  onFormSubmit() {
    const invalidElements = this.el.nativeElement.querySelectorAll('input.ng-invalid');
    if (invalidElements.length > 0) {
      invalidElements[0].focus();
    }
  }
}
